// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import ApexCharts from 'apexcharts';
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

import '../src/modal';
// styles
import 'stylesheets/ibyt.scss'
// use midea/images
require.context('../images', true)

window.ApexCharts = ApexCharts;

(function() {
  $(".dropdown-actions").hide();

  $(".dropdown-toggle").on("click", {capture: false}, function(e){
    e.stopPropagation();
    $(".dropdown-actions").toggle();
  });
})();
