(function() {
  $('.modal__show').on('click', function(e) {
    e.preventDefault();

    $('body').addClass('modal-open');
    $('.modal.show').removeClass('show');
    $(`#${$(this).data('modal')}`).addClass('show');
  });

  $('.modal__close').on('click', function(e) {
    e.preventDefault();

    $('body').removeClass('modal-open');
    $('.modal.show').removeClass('show');
  });

  $('.modal__dismiss').on('click', function(e) {
    e.preventDefault();

    $('body').removeClass('modal-open');
    $('.modal.show').removeClass('show');
  });
})();